require('./src/global.css')

const addScript = url => {
  const script = document.createElement('script')
  script.src = url
  document.getElementsByTagName(`head`)[0].appendChild(script)
}
const addAsyncScript = url => {
  const script = document.createElement('script')
  script.src = url
  script.async = true
  document.getElementsByTagName(`head`)[0].appendChild(script)
}
const addInlineAsyncScript = inlineCode => {
  const script = document.createElement('script')
  script.async = true
  script.innerHTML = inlineCode
  document.getElementsByTagName('head')[0].appendChild(script)
}

// eslint-disable-next-line no-unused-vars
exports.onRouteUpdate = s => {
  addScript(`//scripts.iconnode.com/${process.env.GATSBY_WHATCONVERTS_NUMBER}.js`)
  addAsyncScript(`https://www.clickcease.com/monitor/stat.js`)

  addInlineAsyncScript(
    `(function(w,d,t,r,u){var f,n,i;w[u]=w[u]||[],f=function(){var o={ti:"343153686", enableAutoSpaTracking: true};o.q=w[u],w[u]=new UET(o),w[u].push("pageLoad")},n=d.createElement(t),n.src=r,n.async=1,n.onload=n.onreadystatechange=function(){var s=this.readyState;s&&s!=="loaded"&&s!=="complete"||(f(),n.onload=n.onreadystatechange=null)},i=d.getElementsByTagName(t)[0],i.parentNode.insertBefore(n,i)})(window,document,"script","//bat.bing.com/bat.js","uetq");`,
  )
}
